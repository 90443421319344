:root {
  box-sizing: border-box;
  font-size: 16px;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  background: #f5f5dc;
}

/* hero */
.backgroundImage {
  height: clamp(50vh, 70vh, 90vh);
  background-image: url("./Assets/Image/hair-tools.png");
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: 100% auto;
  padding-left: clamp(0.5em, 1em, 2em);
  height:100vh;
}
.hero-title {
  max-width: 18ch;
  padding-top: clamp(0.1em, 1em, 2em);
}
.cta {
  color: #000000;
  margin-top: 4em;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  letter-spacing: 2px;
  background: #ff6464;
  width: fit-content;
  padding: 0.8em 1.2em;
  border-radius: 0.8em;
  border: 2px solid #b20000;
  transition: 100ms ease-in-out;
}
.cta:hover {
  background: "#80000";
  color: #fcedcf;
  border: 2px solid#B20000;
}

a {
  color: #000000;
}

/* about */
.about {
  width: 90%;
  margin: 4em auto;
}
/*forma */

.form {
  display: flex;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
}

/* testemonial */
.testelmonial-avatar {
  flex: 1;
  display: flex;
  align-items: start;
  padding-top: 2em;
  justify-content: center;
}
.testelmonial-text {
  flex: 4;
  font-size: 1.2em;
  padding: 1em;
  line-height: 1.6;
  max-width: 80%;
  align-self: center;
  margin-top: 0;
}
.testelmonial-inner-text {
  border-radius: 0.5em;
  padding: 1.5em;
  padding-left: 2em;
  max-width: 40em;
  position: relative;
  background-color: #e9c8c8;
}
.testelmonial-inner-text::before,
.testelmonial-inner-text::after {
  content: "''";
  font-style: italic;
  position: absolute;
  font-size: 3rem;
}
.testelmonial-inner-text::before {
  left: 0.1em;
  top: 0.5em;
}
.testelmonial-inner-text::after {
  right: 0.4em;
  bottom: -0.2em;
}
.avatar-name {
  justify-content: center;
  align-items: center;
}
.avatar-pic {
  margin-bottom: 0.5em;
}

/*footer */
footer {
  margin-top: 1em;
  padding-block: 0.5em;
  text-align: center;
  border-top: 1px solid #ff6464;
}
@media(max-width:786px){
  .backgroundImage{
    height:60vh;
  }
}
@media(max-width:375px){
  .backgroundImage{
    height:fit-content;
  }
}